window._ = require("lodash");
require("./bootstrap");

import Incident from "./incident";
import Filters from "./filters";
import Parousiologio from "./parousiologio";
import Swal from "sweetalert2";

window.Swal = Swal;
window.addEventListener("load", function () {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
            toast.addEventListener("click", Swal.close);
        },
    });
    window.Toast = Toast;

    if (messagesSuccess.length > 0) {
        messagesSuccess.forEach((message) => {
            Toast.fire({
                icon: "success",
                title: message,
            });
        });
    }
    if (messagesErrors.length > 0) {
        messagesErrors.forEach((message) => {
            Toast.fire({
                icon: "error",
                title: message,
            });
        });
    }

    if (document.querySelector(".incident-addedit")) {
        new Incident();
    }
    if (document.querySelector(".parousiologio-table")) {
        new Parousiologio();
    }
    if (document.querySelector(".filters")) {
        new Filters();
    }
});
