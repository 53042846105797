// https: //mymth.github.io/vanillajs-datepicker/
import { Datepicker, DateRangePicker } from "vanillajs-datepicker";

import el from "../../node_modules/vanillajs-datepicker/js/i18n/locales/el.js";
Object.assign(Datepicker.locales, el);

export default class {
    constructor() {
        this.setDatepickers();
    }
    setDatepickers() {
        let that = this;
        const elem = document.getElementById("range");
        const filtersDateFrom = document.getElementById("filters-date-from");
        const filtersDateTo = document.getElementById("filters-date-to");

        const dateRangePicker = new DateRangePicker(elem, {
            autohide: true,
            format: "dd/mm/yyyy",
            language: "el",
        });

        filtersDateFrom.addEventListener('changeDate', (event)=>{
            window.parousilogioTable.draw();
        });
        filtersDateTo.addEventListener('changeDate', (event)=>{
            window.parousilogioTable.draw();
        });
        // $(".searchEmail").keyup(function() {
        //     table.draw();
        // });
    }
}
