// https: //mymth.github.io/vanillajs-datepicker/
import {
    Datepicker
} from "vanillajs-datepicker";

import {
    Modal
} from "bootstrap";

import Swal from "sweetalert2";
const {
    Dropzone
} = require("dropzone");

import el from "../../node_modules/vanillajs-datepicker/js/i18n/locales/el.js";
import {
    forEach
} from "lodash";
Object.assign(Datepicker.locales, el);

export default class {
    constructor() {
        let that = this;
        window.LaravelDataTables = [];
        that.lastPoreiaTableId;
        that.lastSinergasiesTableId;
        that.lastFilesTableId;

        this.setSelectDropdowns();
        this.setDatepickers();
        if (document.getElementById("amkaSearchModal")) {
            this.searchAmkaModalLogic();
        }
        this.arxikiEntopismosTabLogic();
        this.asthenisEisagwgiTabLogic();
        this.proseggisiOikogeniasTabLogic();
        this.ekvasiTabLogic();

        this.setDatatablePoreiaEgkefalikosThanatos();
        if (document.getElementById("poreiaModal")) {
            this.poreiaModalLogic();
        }
        this.showPoreiaModalLogic();

        this.setDatatableSinergasies();
        if (document.getElementById("sinergasiesModal")) {
            this.sinergasiesModalLogic();
        }

        this.setDatatableFiles();
        this.filesModalLogic();

        if (window.isNewRecord) {
            document
                .querySelector("#poreiaAccordionItem")
                .classList.add("d-none");
            document
                .querySelector("#proseggisiOikogeneiasAccordionItem")
                .classList.add("d-none");
            document
                .querySelector("#ekvasiAccordionItem")
                .classList.add("d-none");
            document
                .querySelector("#sunergatesAccordionItem")
                .classList.add("d-none");
            document
                .querySelector("#aksiologisiAccordionItem")
                .classList.add("d-none");
            document
                .querySelector("#filesAccordionItem")
                .classList.add("d-none");
        } else {
            this.extraTabInfo();
        }
    }

    extraTabInfo() {
        var filoValue = $("#filo").select2("data");
        document.querySelectorAll(".extra-tab-info").forEach((extraTab) => {
            extraTab.innerHTML =
                "<span>ID " +
                document.querySelector("#am-eom").value +
                "</span>";
            if (filoValue) {
                extraTab.innerHTML += "<span>" + filoValue[0].text + "</span>";
            }
            extraTab.innerHTML +=
                "<span>" + document.querySelector("#ilikia").value + "</span>";
        });
    }

    searchAmkaModalLogic() {
        var amkaSearchModal = new Modal(
            document.getElementById("amkaSearchModal")
        );

        document
            .getElementById("amkaSearchModal")
            .addEventListener("shown.bs.modal", function () {
                document.getElementById("amkaSearchTextField").focus();
            });

        $("#amkaSearchButton").on("click", function (e) {
            e.preventDefault();
            var amka = $("input#amkaSearchTextField").val();
            var amEom = $("input#am-eom").val();
            $.ajax({
                type: "POST",
                url: route("ajax.amkaSearchExists"),
                data: {
                    amEom: amEom,
                    amka: amka,
                },
                success: function (data) {
                    if (data.exists) {
                        $("#amka").val(amka);
                        amkaSearchModal.hide();
                        Toast.fire({
                            icon: "success",
                            title: data.message,
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: data.message,
                        });
                    }
                },
            });
        });
    }

    setDatatablePoreiaEgkefalikosThanatos() {
        let that = this;
        this.poreiaTable = $(".poreia-table").DataTable({
            language: {
                url: window.__ASSET_URL__ + "/el.json",
            },
            processing: true,
            serverSide: true,
            ajax: {
                url: route("ajax.getPoreiaTable"),
                type: "POST",
                data: function (data) {
                    data.amEOM = $("#am-eom").val();
                },
                dataSrc: function (json) {
                    if (json.data.length > 0) {
                        that.lastPoreiaTableId = json.data[0].real_id;
                    }
                    return json.data;
                },
            },
            order: [
                [0, "desc"]
            ],
            columns: [{
                    data: "id",
                    name: "id",
                },
                {
                    data: "nea-enimerosi",
                    name: "nea-enimerosi.timestamp",
                    type: "num",
                    render: {
                        _: "display",
                        sort: "timestamp",
                    },
                },
                {
                    data: "poreia",
                    name: "poreia",
                },
                {
                    data: "programmatismos-a-dokimasiwn",
                    name: "programmatismos-a-dokimasiwns.timestamp",
                    type: "num",
                    render: {
                        _: "display",
                        sort: "timestamp",
                    },
                },
                {
                    data: "apotelesma-a-dokimasiwn",
                    name: "apotelesma-a-dokimasiwn",
                },
                {
                    data: "programmatismos-b-dokimasiwn",
                    name: "programmatismos-b-dokimasiwns.timestamp",
                    type: "num",
                    render: {
                        _: "display",
                        sort: "timestamp",
                    },
                },
                {
                    data: "apotelesma-b-dokimasiwn",
                    name: "apotelesma-b-dokimasiwn",
                },
                {
                    data: "aitia-thanatou",
                    name: "aitia-thanatou",
                },
                {
                    data: "action",
                    name: "action",
                    orderable: false,
                    searchable: false,
                },
            ],
        });
        window.LaravelDataTables["poreiaTable"] = this.poreiaTable;
    }

    setDatatableSinergasies() {
        let that = this;
        this.sinergasiesTable = $(".sinergasies-table").DataTable({
            language: {
                url: window.__ASSET_URL__ + "/el.json",
            },
            "ordering": false,
            processing: true,
            serverSide: true,
            ajax: {
                url: route("ajax.getSinergasiesTable"),
                type: "POST",
                data: function (data) {
                    data.amEOM = $("#am-eom").val();
                },
                dataSrc: function (json) {
                    if (json.data.length > 0) {
                        that.lastSinergasiesTableId = json.data[0].id;
                    }
                    return json.data;
                },
            },
            order: [
                [0, "desc"]
            ],
            columns: [{
                    data: "id",
                    name: "id",
                },
                {
                    data: "onoma",
                    name: "onoma",
                },
                {
                    data: "epitheto",
                    name: "epitheto",
                },
                {
                    data: "idiotita",
                    name: "idiotita",
                },
                {
                    data: "kliniki",
                    name: "kliniki",
                },
                {
                    data: "nosokomeio-foreas",
                    name: "nosokomeio-foreas",
                },
                {
                    data: "action",
                    name: "action",
                    orderable: false,
                    searchable: false,
                },
            ],
        });
        window.LaravelDataTables["sinergasiesTable"] = this.sinergasiesTable;
    }

    setDatatableFiles() {
        let that = this;
        this.filesTable = $(".files-table").DataTable({
            language: {
                url: window.__ASSET_URL__ + "/el.json",
            },
            "ordering": false,
            processing: true,
            serverSide: true,
            ajax: {
                url: route("file.getFilesTable"),
                type: "POST",
                data: function (data) {
                    data.amEOM = $("#am-eom").val();
                },
                dataSrc: function (json) {
                    if (json.data.length > 0) {
                        that.lastFilesTableId = json.data[0].id;
                    }
                    return json.data;
                },
            },
            order: [
                [0, "desc"]
            ],
            columns: [{
                    data: "id",
                    name: "id",
                },
                {
                    data: "name",
                    name: "name",
                },
                {
                    data: "file_category",
                    name: "file_category",
                },
                {
                    data: "updated_at",
                    name: "updated_at.timestamp",
                    type: "num",
                    render: {
                        _: "display",
                        sort: "timestamp",
                    },
                },
                {
                    data: "action",
                    name: "action",
                    orderable: false,
                    searchable: false,
                },
            ],
        });
        window.LaravelDataTables["filesTable"] = this.filesTable;
        window.LaravelDataTables["filesTable"].ajax.reload(
            that.onReloadFilesTable
        );

        // Dropzone.prototype.defaultOptions.dictDefaultMessage = "Drop files here to upload2";
        // Dropzone.prototype.defaultOptions.dictFallbackMessage = "Your browser does not support drag'n'drop file uploads.";
        // Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
        // Dropzone.prototype.defaultOptions.dictFileTooBig = "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.";
        // Dropzone.prototype.defaultOptions.dictInvalidFileType = "You can't upload files of this type.";
        // Dropzone.prototype.defaultOptions.dictResponseError = "Server responded with {{statusCode}} code.";
        // Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancel upload";
        // Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Are you sure you want to cancel this upload?";
        // Dropzone.prototype.defaultOptions.dictRemoveFile = "Remove file";
        // Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "You can not upload any more files.";

        // https://appdividend.com/2022/02/28/laravel-file-upload/
        if (document.querySelector("#file")) {
            var drop = new Dropzone("#file", {
                dictDefaultMessage: "Ανεβάστε το αρχείο σας",
                dictFallbackMessage: "Το πρόγραμμα περιήγησής σας δεν υποστηρίζει μεταφορτώσεις αρχείων με drag'n'drop.",
                dictFallbackText: "Χρησιμοποιήστε την παρακάτω φόρμα εναλλακτικής για να ανεβάσετε τα αρχεία σας όπως παλιά.",
                dictFileTooBig: "Το αρχείο είναι πολύ μεγάλο ({{filesize}}MiB). Μέγιστο μέγεθος αρχείου: {{maxFilesize}}MiB.",
                dictInvalidFileType: "Δεν μπορείτε να ανεβάσετε αρχεία αυτού του τύπου.",
                dictResponseError: "Ο διακομιστής απάντησε με {{statusCode}} code.",
                dictCancelUpload: "Ακύρωση μεταφόρτωσης",
                dictCancelUploadConfirmation: "Είστε βέβαιοι ότι θέλετε να ακυρώσετε αυτήν τη μεταφόρτωση?",
                dictRemoveFile: "Κατάργηση αρχείου",
                dictMaxFilesExceeded: "Δεν μπορείτε να ανεβάσετε άλλα αρχεία.",

                createImageThumbnails: false,
                uploadMultiple: false,
                addRemoveLinks: false,
                url: route("upload"),
                method: "post",
                headers: {
                    "X-CSRF-TOKEN": document.head.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
            });
            drop.on("addedfile", function (file) {
                var maxFiles = 1;
                for (var i = drop.files.length - maxFiles - 1; i >= 0; i--) {
                    var f = drop.files[i];
                    if (f.upload.uuid !== file.upload.uuid) drop.removeFile(f);
                }
            });
            drop.on("success", function () {
                var args = Array.prototype.slice.call(arguments);
                let fileId = args[1].id;
                document.querySelector("#fileId").value = fileId;
            });
        }
    }

    onReloadFilesTable(json) {
        // console.log(window.requiredFileCategories);
        let requiredCategories = [];
        window.requiredFileCategories.forEach((category) => {
            requiredCategories.push(category.id);
        });
        let tableCategories = [];
        json.data.forEach((row) => {
            // console.log(row.file_category_id);
            tableCategories.push(row.file_category_id);
        });
        const containsAll = requiredCategories.every((element) => {
            return tableCategories.includes(element);
        });
        // console.log(containsAll);
        if (containsAll) {
            document.querySelector(".se-ekkremotita").classList.add("d-none");
        } else {
            document
                .querySelector(".se-ekkremotita")
                .classList.remove("d-none");
        }
    }

    setSelectDropdowns() {
        $(".incident-addedit .form-select-sm").each(function (index) {
            $(this).select2({
                allowClear: true,
                placeholder: "Παρακαλώ επιλέξτε",
                theme: "bootstrap-5",
                dropdownParent: $(this).parent(), // Required for dropdown styling
            });
        });
        $("#poreiaModal .form-select-sm").each(function (index) {
            $(this).select2({
                allowClear: true,
                placeholder: "Παρακαλώ επιλέξτε",
                theme: "bootstrap-5",
                dropdownParent: $("#poreiaModal"), // Required for dropdown styling
            });
        });
        $("#showPoreiaModal .form-select-sm").each(function (index) {
            $(this).select2({
                allowClear: true,
                placeholder: "Παρακαλώ επιλέξτε",
                theme: "bootstrap-5",
                dropdownParent: $("#showPoreiaModal"), // Required for dropdown styling
            });
        });
    }

    setDatepickers() {
        let that = this;
        const imerominiaEntopismou = new Datepicker(
            document.querySelector("#imerominia-entopismou"), {
                autohide: true,
                format: "dd/mm/yyyy",
                language: "el",
                maxDate: "today",
            }
        );
        const imerominiaGennisis = new Datepicker(
            document.querySelector("#imerominia-gennisis"), {
                autohide: true,
                format: "dd/mm/yyyy",
                language: "el",
                maxDate: "today",
            }
        );
        document
            .querySelector("#imerominia-gennisis")
            .addEventListener("changeDate", (e) => {
                // update age value
                let age = that.getAge(imerominiaGennisis.getDate());
                document.querySelector("#ilikia").value = age;
            });
        const imerominiaEisagwgis = new Datepicker(
            document.querySelector("#imerominia-eisagwgis"), {
                autohide: true,
                format: "dd/mm/yyyy",
                language: "el",
                maxDate: "today",
            }
        );
        if (document.querySelector("#nea-enimerosi")) {
            const neaEnimerosi = new Datepicker(
                document.querySelector("#nea-enimerosi"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                    maxDate: "today",
                }
            );
        }
        if (document.querySelector("#enarksi-katastolis")) {
            const enarksiKatastolis = new Datepicker(
                document.querySelector("#enarksi-katastolis"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#diakopi-katastolis")) {
            const diakopiKatastolis = new Datepicker(
                document.querySelector("#diakopi-katastolis"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#imerominia-exetasis-epipedon-katastaltikon")) {
            const imerominiaExetasisEpipedonKatastaltikon = new Datepicker(
                document.querySelector(
                    "#imerominia-exetasis-epipedon-katastaltikon"
                ), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#programmatismos-a-dokimasiwn")) {
            const programmatismosADokimasiwn = new Datepicker(
                document.querySelector("#programmatismos-a-dokimasiwn"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#programmatismos-b-dokimasiwn")) {
            const programmatismosBDokimasiwn = new Datepicker(
                document.querySelector("#programmatismos-b-dokimasiwn"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#programmatismos-chirourgiou")) {
            const programmatismosChirourgiou = new Datepicker(
                document.querySelector("#programmatismos-chirourgiou"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#imerominia-chirourgiou")) {
            const imerominiaChirourgiou = new Datepicker(
                document.querySelector("#imerominia-chirourgiou"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#imerominia-diaswlinwsis")) {
            const imerominiaDiaswlinwsis = new Datepicker(
                document.querySelector("#imerominia-diaswlinwsis"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                }
            );
        }
        if (document.querySelector("#imerominia-arxikou-diagnostikou-elegxou")) {
            const imerominiaArxikouDiagnostikouElegxou = new Datepicker(
                document.querySelector("#imerominia-arxikou-diagnostikou-elegxou"), {
                    autohide: true,
                    format: "dd/mm/yyyy",
                    language: "el",
                    maxDate: "today",
                }
            );
        }
        // const imerominiaTeleutaiouDiagnostikouElegxou = new Datepicker(
        //     document.querySelector(
        //         "#imerominia-teleutaiou-diagnostikou-elegxou"
        //     ),
        //     {
        //         autohide: true,
        //         format: "dd/mm/yyyy",
        //         language: "el",
        //         maxDate: "today",
        //     }
        // );
    }

    arxikiEntopismosTabLogic() {
        let that = this;
        $("#agnwsta-stoixeia").on("change", function () {
            that.agnwstaStoixeiaChange();
        });
        this.agnwstaStoixeiaChange();
    }

    asthenisEisagwgiTabLogic() {
        let that = this;
        $("#zotika-simeia").on("change", function () {
            that.zotikaSimeiaChange();
        });
        this.zotikaSimeiaChange();
        $("#diasolinwsi").on("change", function () {
            that.diasolinwsiChange();
            that.anazoogonisiChange();
        });
        $("#anazoogonisi").on("change", function () {
            that.diasolinwsiChange();
            that.anazoogonisiChange();
        });
        $("#kliniki-nosilias").on("change", function () {
            that.klinikiNosiliasChange();
        });
        that.klinikiNosiliasChange();
        that.diasolinwsiChange();
        that.anazoogonisiChange();
    }

    proseggisiOikogeniasTabLogic() {
        let that = this;
        $("#sizitithike-opiadipote-stigmi").on("change", function () {
            that.sizitithikeOpiadipoteStigmiChange();
        });
        this.sizitithikeOpiadipoteStigmiChange();
        $("#simperasma-sizitisis").on("change", function () {
            that.simperasmaSizitisisChange();
        });
        this.simperasmaSizitisisChange();
    }

    ekvasiTabLogic() {
        let that = this;
        $("#pragmatopiithike-lipsi").on("change", function () {
            that.pragmatopiithikeLipsiChange();
        });
        this.pragmatopiithikeLipsiChange();
        $("#aities-mi-lipsis").on("change", function () {
            that.aitiesMiLipsisChange();
        });
        this.aitiesMiLipsisChange();
    }

    poreiaModalLogic() {
        let that = this;
        var poreiaModal = new Modal(document.getElementById("poreiaModal"));

        $("#poreia").on("change", function () {
            that.poreiaChange();
        });
        this.poreiaChange();

        $("#apotelesma-a-dokimasiwn").on("change", function () {
            that.apotelesmaΑDokimasiwnChange();
            that.apotelesmaDokimasiwnChange();
        });
        this.apotelesmaΑDokimasiwnChange();

        $("#apotelesma-b-dokimasiwn").on("change", function () {
            that.apotelesmaDokimasiwnChange();
            that.gdprFieldsChange();
        });
        $("#eidos-doti").on("change", function () {
            that.gdprFieldsChange();
        });
        this.apotelesmaDokimasiwnChange();
        this.gdprFieldsChange();

        $("#telesi-aggeiografias").on("change", function () {
            that.telesiAggeiografeiasChange();
        });
        this.telesiAggeiografeiasChange();

        document
            .getElementById("poreiaModal")
            .addEventListener("show.bs.modal", function () {
                var poreiaId = document.getElementById("enimerwsiPoreiaButton")
                    .dataset.poreiaId;
                that.gePoreiaModalValues(poreiaId);
            });

        $("#poreiaModalSaveButton").on("click", (e) => {
            $.ajax({
                type: "POST",
                url: route("ajax.savePoreiaAction"),
                data: {
                    amEOM: $("#am-eom").val(),
                    "nea-enimerosi": $("#nea-enimerosi").val(),
                    poreia: $("#poreia").val(),
                    "nx-parembasi": $("#nx-parembasi").val(),
                    "lipsi-katastolis": $("#lipsi-katastolis").val(),
                    "enarksi-katastolis": $("#enarksi-katastolis").val(),
                    "eidos-katastolis": $("#eidos-katastolis").val(),
                    "diakopi-katastolis": $("#diakopi-katastolis").val(),
                    "imerominia-exetasis-epipedon-katastaltikon": $(
                        "#imerominia-exetasis-epipedon-katastaltikon"
                    ).val(),
                    "apotelesma-epipedwn-katastaltikwn": $(
                        "#apotelesma-epipedwn-katastaltikwn"
                    ).val(),
                    "thetika-simeia-egk-thanatou": $(
                        "#thetika-simeia-egk-thanatou"
                    ).val(),
                    "programmatismos-a-dokimasiwn": $(
                        "#programmatismos-a-dokimasiwn"
                    ).val(),
                    "programmatismos-a-dokimasiwn-time": $(
                        "#programmatismos-a-dokimasiwn-time"
                    ).val(),
                    "programmatismos-b-dokimasiwn": $(
                        "#programmatismos-b-dokimasiwn"
                    ).val(),
                    "programmatismos-b-dokimasiwn-time": $(
                        "#programmatismos-b-dokimasiwn-time"
                    ).val(),
                    "telesi-dokimasiwn": $("#telesi-dokimasiwn").val(),
                    "kliniki-telesis-twn-dokimasiwn": $(
                        "#kliniki-telesis-twn-dokimasiwn"
                    ).val(),
                    "apotelesma-a-dokimasiwn": $(
                        "#apotelesma-a-dokimasiwn"
                    ).val(),
                    "apotelesma-b-dokimasiwn": $(
                        "#apotelesma-b-dokimasiwn"
                    ).val(),
                    "apotelesmata-dokimasias-arnitika-gia-egk-thanato": $(
                        "#apotelesmata-dokimasias-arnitika-gia-egk-thanato"
                    ).val(),
                    "aities-mi-pragmatopoisiis-twn-dokimasiwn-egk-than": $(
                        "#aities-mi-pragmatopoisiis-twn-dokimasiwn-egk-than"
                    ).val(),
                    "telesi-aggeiografias": $("#telesi-aggeiografias").val(),
                    "apotelesma-aggeiografias": $(
                        "#apotelesma-aggeiografias"
                    ).val(),
                    "aitia-thanatou": $("#aitia-thanatou").val(),
                },
                success: function (data) {
                    if (data.errors) {
                        data.errors.forEach((message) => {
                            Toast.fire({
                                icon: "error",
                                title: message,
                            });
                        });
                    } else {
                        if (data.success) {
                            window.LaravelDataTables[
                                "poreiaTable"
                            ].ajax.reload();
                            poreiaModal.hide();
                            Toast.fire({
                                icon: "success",
                                title: data.message,
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: data.message,
                            });
                        }
                    }
                },
                error: function (jqXhr, json, errorThrown) {
                    // this are default for ajax errors
                    var errors = jqXhr.responseJSON;
                    var errorsHtml = "";
                    $.each(errors["errors"], function (index, value) {
                        errorsHtml += value + "<br>";
                    });
                    Toast.fire({
                        icon: "error",
                        title: errorsHtml,
                    });
                },
            });
        });
    }

    gePoreiaModalValues(poreiaId) {
        let that = this;
        $.ajax({
            type: "POST",
            url: route("ajax.getPoreiaData"),
            data: {
                poreiaId: that.lastPoreiaTableId,
                purpose: "add",
            },
            success: function (data) {
                if (data.exists) {
                    var result = [];

                    for (var i in data.rowdata)
                        result.push([i, data.rowdata[i]]);

                    console.log(result);
                    result.forEach((element) => {
                        if (document.querySelector("#" + element[0])) {
                            let elementDom = document.querySelector(
                                "#" + element[0]
                            );
                            switch (elementDom.nodeName) {
                                case "INPUT":
                                    var filterDashValue = element[1];
                                    if (filterDashValue == "-") {
                                        filterDashValue = "";
                                    }
                                    elementDom.value = filterDashValue;
                                    break;
                                case "SELECT":
                                    let optionValueArray = [];
                                    Array.from(elementDom.options).forEach(
                                        (option) => {
                                            element[1]
                                                .split(", ")
                                                .forEach((tmpOption) => {
                                                    if (
                                                        option.text.trim() ==
                                                        tmpOption.trim()
                                                    ) {
                                                        optionValueArray.push(
                                                            option.value
                                                        );
                                                    }
                                                });
                                        }
                                    );
                                    // if (element[0] == 'thetika-simeia-egk-thanatou') {
                                    //     console.log(element);
                                    //     console.log(elementDom.options);
                                    //     console.log(optionValueArray);
                                    // }
                                    $("#" + element[0])
                                        .val(optionValueArray)
                                        .trigger("change");
                                    break;
                                default:
                                    // console.log(elementDom);
                                    // console.log(elementDom.nodeName);
                                    break;
                            }
                        }
                    });
                }
            },
        });
    }

    sinergasiesModalLogic() {
        let that = this;
        var sinergasiesModal = new Modal(
            document.getElementById("sinergasiesModal")
        );

        $(document).on("click", ".deleteSinergasies", function (e) {
            e.preventDefault();
            var id = $(this).data("sinergasies-id");

            Swal.fire({
                title: "Είστε σίγουρος;",
                text: "Δεν θα μπορείτε να το επαναφέρετε!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Διαγραφή",
                cancelButtonText: "Άκυρο",
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        type: "POST",
                        url: route("ajax.deleteSinergasies"),
                        data: {
                            amEOM: $("#am-eom").val(),
                            sinergasiesId: id,
                        },
                        success: function (data) {
                            if (data.errors) {
                                data.errors.forEach((message) => {
                                    Toast.fire({
                                        icon: "error",
                                        title: message,
                                    });
                                });
                            } else {
                                if (data.success) {
                                    window.LaravelDataTables[
                                        "sinergasiesTable"
                                    ].ajax.reload();
                                    sinergasiesModal.hide();
                                    Toast.fire({
                                        icon: "success",
                                        title: data.message,
                                    });
                                } else {
                                    Toast.fire({
                                        icon: "error",
                                        title: data.message,
                                    });
                                }
                            }
                        },
                        error: function (jqXhr, json, errorThrown) {
                            // this are default for ajax errors
                            var errors = jqXhr.responseJSON;
                            var errorsHtml = "";
                            $.each(errors["errors"], function (index, value) {
                                errorsHtml += value + "<br>";
                            });
                            Toast.fire({
                                icon: "error",
                                title: errorsHtml,
                            });
                        },
                    });
                }
            });
        });

        $("#sinergasiesModalSaveButton").on("click", (e) => {
            $.ajax({
                type: "POST",
                url: route("ajax.saveSinergasiesAction"),
                data: {
                    amEOM: $("#am-eom").val(),
                    sinergasiesOnoma: $("#sinergasiesOnoma").val(),
                    sinergasiesEpitheto: $("#sinergasiesEpitheto").val(),
                    idiotita: $("#idiotita").val(),
                    kliniki: $("#kliniki").val(),
                    "nosokomeio-foreas": $("#nosokomeio-foreas").val(),
                },
                success: function (data) {
                    if (data.errors) {
                        data.errors.forEach((message) => {
                            Toast.fire({
                                icon: "error",
                                title: message,
                            });
                        });
                    } else {
                        if (data.success) {
                            window.LaravelDataTables[
                                "sinergasiesTable"
                            ].ajax.reload();
                            sinergasiesModal.hide();
                            Toast.fire({
                                icon: "success",
                                title: data.message,
                            });
                            $("#sinergasiesOnoma").val("");
                            $("#sinergasiesEpitheto").val("");
                            $("#idiotita").val("");
                            $("#kliniki").val("");
                            $("#nosokomeio-foreas").val("");
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: data.message,
                            });
                        }
                    }
                },
                error: function (jqXhr, json, errorThrown) {
                    // this are default for ajax errors
                    var errors = jqXhr.responseJSON;
                    var errorsHtml = "";
                    $.each(errors["errors"], function (index, value) {
                        errorsHtml += value + "<br>";
                    });
                    Toast.fire({
                        icon: "error",
                        title: errorsHtml,
                    });
                },
            });
        });
    }

    filesModalLogic() {
        let that = this;
        if (!document.getElementById("filesModal")) {
            return;
        }
        var filesModal = new Modal(document.getElementById("filesModal"));

        $("#filesModalSaveButton").on("click", (e) => {
            $.ajax({
                type: "POST",
                url: route("file.upload"),
                data: {
                    amEOM: $("#am-eom").val(),
                    fileId: $("#fileId").val(),
                    file_category: $("#file_category").val(),
                    file_onoma: $("#file_onoma").val(),
                },
                success: function (data) {
                    if (data.errors) {
                        data.errors.forEach((message) => {
                            Toast.fire({
                                icon: "error",
                                title: message,
                            });
                        });
                    } else {
                        if (data.success) {
                            window.LaravelDataTables["filesTable"].ajax.reload(
                                that.onReloadFilesTable
                            );
                            filesModal.hide();
                            Toast.fire({
                                icon: "success",
                                title: data.message,
                            });
                            $("#fileId").val("");
                            $("#file_category").val("");
                            $("#file_onoma").val("");
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: data.message,
                            });
                        }
                    }
                },
                error: function (jqXhr, json, errorThrown) {
                    // this are default for ajax errors
                    var errors = jqXhr.responseJSON;
                    var errorsHtml = "";
                    $.each(errors["errors"], function (index, value) {
                        errorsHtml += value + "<br>";
                    });
                    Toast.fire({
                        icon: "error",
                        title: errorsHtml,
                    });
                },
            });
        });

        $(document).on("click", ".deleteFile", function (e) {
            e.preventDefault();
            var id = $(this).data("file-id");

            Swal.fire({
                title: "Είστε σίγουρος;",
                text: "Δεν θα μπορείτε να το επαναφέρετε!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Διαγραφή",
                cancelButtonText: "Άκυρο",
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        type: "POST",
                        url: route("file.deleteFile"),
                        data: {
                            amEOM: $("#am-eom").val(),
                            fileId: id,
                        },
                        success: function (data) {
                            if (data.errors) {
                                data.errors.forEach((message) => {
                                    Toast.fire({
                                        icon: "error",
                                        title: message,
                                    });
                                });
                            } else {
                                if (data.success) {
                                    window.LaravelDataTables[
                                        "filesTable"
                                    ].ajax.reload(that.onReloadFilesTable);
                                    filesModal.hide();
                                    Toast.fire({
                                        icon: "success",
                                        title: data.message,
                                    });
                                } else {
                                    Toast.fire({
                                        icon: "error",
                                        title: data.message,
                                    });
                                }
                            }
                        },
                        error: function (jqXhr, json, errorThrown) {
                            // this are default for ajax errors
                            var errors = jqXhr.responseJSON;
                            var errorsHtml = "";
                            $.each(errors["errors"], function (index, value) {
                                errorsHtml += value + "<br>";
                            });
                            Toast.fire({
                                icon: "error",
                                title: errorsHtml,
                            });
                        },
                    });
                }
            });
        });
    }

    showPoreiaModalLogic() {
        var showPoreiaModal = new Modal(
            document.getElementById("showPoreiaModal")
        );
        document
            .getElementById("showPoreiaModal")
            .addEventListener("show.bs.modal", function (event) {
                // Button that triggered the modal
                var poreiaId = $(event.relatedTarget).attr("data-poreia-id");

                $.ajax({
                    type: "POST",
                    url: route("ajax.getPoreiaData"),
                    data: {
                        poreiaId: poreiaId,
                        purpose: "view",
                    },
                    success: function (data) {
                        if (data.exists) {
                            showPoreiaModal.hide();

                            var result = [];

                            for (var i in data.rowdata)
                                result.push([i, data.rowdata[i]]);

                            result.forEach((element) => {
                                if (
                                    document.querySelector(
                                        ".showPoreiaModal-" + element[0]
                                    )
                                ) {
                                    document.querySelector(
                                        ".showPoreiaModal-" + element[0]
                                    ).innerHTML = element[1];
                                }
                            });
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: data.message,
                            });
                        }
                    },
                });
            });
    }

    getAge(birthDate) {
        var today = new Date();
        var age = today.getFullYear() - birthDate.getFullYear();
        console.log(age);
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    // =======================================
    // Agnwsta Stoixeia Field Logic
    // =======================================
    agnwstaStoixeiaChange() {
        // Init: Hide fields
        document
            .querySelector("#arxikoOnomatosFieldColumn")
            .classList.add("disabled");
        document
            .querySelector("#arxikoEpithetouFieldColumn")
            .classList.add("disabled");

        let agnwstaStoixeiaValue = document.querySelector("#agnwsta-stoixeia");
        if (agnwstaStoixeiaValue.checked == true) {
            // Hide Fields and Accordion Items
            document
                .querySelector("#arxikoOnomatosFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#arxikoEpithetouFieldColumn")
                .classList.add("disabled");
        } else {
            // Show Fields and Accordion Items
            document
                .querySelector("#arxikoOnomatosFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#arxikoEpithetouFieldColumn")
                .classList.remove("disabled");
        }
    }

    // =======================================
    // Zotika Simeia Field Logic
    // =======================================
    zotikaSimeiaChange() {
        // Init: Hide fields
        document
            .querySelector("#prospatheiaAnazoogonisisFieldColumn")
            .classList.add("disabled");
        document
            .querySelector("#anazoogonisiFieldColumn")
            .classList.add("disabled");
        // document.querySelector('#diasolinwsiFieldColumn').classList.add('disabled');
        document
            .querySelector("#klimakaGklaskovisFieldColumn")
            .classList.add("disabled");

        let zotikaSimeiaValue = document.querySelector("#zotika-simeia").value;
        if (zotikaSimeiaValue == 1) {
            // NAI
            // Show Fields and Accordion Items
            // document.querySelector('#diasolinwsiFieldColumn').classList.remove('disabled');
            document
                .querySelector("#klimakaGklaskovisFieldColumn")
                .classList.remove("disabled");
            // Hide Fields and Accordion Items
            document
                .querySelector("#prospatheiaAnazoogonisisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#anazoogonisiFieldColumn")
                .classList.add("disabled");
        } else if (zotikaSimeiaValue == 2) {
            // OXI
            // Show Fields and Accordion Items
            document
                .querySelector("#prospatheiaAnazoogonisisFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#anazoogonisiFieldColumn")
                .classList.remove("disabled");
            // Hide Fields and Accordion Items
            // document.querySelector('#diasolinwsiFieldColumn').classList.add('disabled');
            document
                .querySelector("#klimakaGklaskovisFieldColumn")
                .classList.add("disabled");
        }

        // let diasolinwsiValue = document.querySelector('#diasolinwsi').value;
        // if (diasolinwsiValue == 2 || zotikaSimeiaValue == 1) {
        //     document.querySelector('#klimakaGklaskovisFieldColumn').classList.remove('disabled');
        // }
    }

    // =======================================
    // Kliniki Nosilias Field Logic
    // =======================================
    klinikiNosiliasChange() {
        document
            .querySelector("#alliKlinikiNosiliasFieldColumn")
            .classList.add("disabled");

        let klinikiNosiliasValue = document.querySelector("#kliniki-nosilias").value;
        if (klinikiNosiliasValue == 999999) {
            document
                .querySelector("#alliKlinikiNosiliasFieldColumn")
                .classList.remove("disabled");
        }
    }

    // =======================================
    // Diasolinwsi Field Logic
    // =======================================
    diasolinwsiChange() {
        return; //akuro mexri neoteras
        // Init: Hide fields
        document
            .querySelector("#klimakaGklaskovisFieldColumn")
            .classList.add("disabled");

        let zotikaSimeiaValue = document.querySelector("#zotika-simeia").value;
        let diasolinwsiValue = document.querySelector("#diasolinwsi").value;
        if (diasolinwsiValue == 2 || zotikaSimeiaValue == 1) {
            // OXI
            // Show Fields and Accordion Items
            document
                .querySelector("#klimakaGklaskovisFieldColumn")
                .classList.remove("disabled");
        } else {
            // !OXI
            // Hide Fields and Accordion Items
            document
                .querySelector("#klimakaGklaskovisFieldColumn")
                .classList.add("disabled");
        }
    }

    // =======================================
    // Anazoogonisi Field Logic
    // =======================================
    anazoogonisiChange() {
        return; //akuro mexri neoteras
        // Init: Hide fields
        document
            .querySelector("#diasolinwsiFieldColumn")
            .classList.add("disabled");

        let anazoogonisiValue = document.querySelector("#anazoogonisi").value;
        if (anazoogonisiValue == 1) {
            // ΕΠΙΤΥΧΩΣ
            // Show Fields and Accordion Items
            document
                .querySelector("#diasolinwsiFieldColumn")
                .classList.remove("disabled");
        } else {
            // !ΕΠΙΤΥΧΩΣ
            // Hide Fields and Accordion Items
            document
                .querySelector("#diasolinwsiFieldColumn")
                .classList.add("disabled");
        }
    }

    // =======================================
    // Poreia Field Logic
    // =======================================
    poreiaChange() {
        // Init: Hide fields
        // document.querySelector('#thetikaSimeiaEgkThanatouFieldColumn').classList.add('disabled');

        let poreiaValue = document.querySelector("#poreia").value;
        if (poreiaValue == 2 || poreiaValue == 3) {
            // ΣΤΑΣΙΜΗ
            // ΕΠΙΔΕΙΝΩΣΗ
            // document.querySelector('#thetikaSimeiaEgkThanatouFieldColumn').classList.remove('disabled');
        } else if (poreiaValue == 1) {
            // ΒΕΛΤΙΩΣΗ
            // document.querySelector('#thetikaSimeiaEgkThanatouFieldColumn').classList.add('disabled');
        }
    }

    // =======================================
    // Enable GDPR fields
    // =======================================
    gdprFieldsChange() {
        document
            .querySelector("#meta-pistopoiisi-thanatou")
            .classList.add("d-none");

        let latestApotelesmaBDokimasiwn = document.querySelector(
            "#meta-pistopoiisi-thanatou"
        ).dataset.latestApotelesmaBDokimasiwn;

        document.querySelector("#amkaFieldColumn").classList.add("disabled");
        document
            .querySelector("#amNosokomeiouFieldColumn")
            .classList.add("disabled");
        document
            .querySelector("#arithmosDiavatiriouFieldColumn")
            .classList.add("disabled");
        document
            .querySelector("#epithetoFieldColumn")
            .classList.add("disabled");
        document.querySelector("#onomaFieldColumn").classList.add("disabled");
        document
            .querySelector("#onomaPatrosFieldColumn")
            .classList.add("disabled");
        document
            .querySelector("#imerominiaGennisisFieldColumn")
            .classList.add("disabled");
        document
            .querySelector("#ilikiaFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#arxikoOnomatosFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#arxikoEpithetouFieldColumn")
            .classList.remove("disabled");

        let apotelesmaBDokimasiwnValue = document.querySelector(
            "#apotelesma-b-dokimasiwn"
        ).value;
        let eidosDotiValue = document.querySelector("#eidos-doti").value;

        let epithetoValue = document.querySelector(
            "#epithetoFieldColumn #epitheto"
        ).value;
        let onomaValue = document.querySelector(
            "#onomaFieldColumn #onoma"
        ).value;
        let onomaPatrosValue = document.querySelector(
            "#onomaPatrosFieldColumn #onoma-patros"
        ).value;

        if (
            apotelesmaBDokimasiwnValue == 1 ||
            eidosDotiValue == 2 ||
            latestApotelesmaBDokimasiwn
        ) {
            document
                .querySelector("#meta-pistopoiisi-thanatou")
                .classList.remove("d-none");
            document
                .querySelector("#amkaFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#amNosokomeiouFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#arithmosDiavatiriouFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#epithetoFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#onomaFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#onomaPatrosFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#imerominiaGennisisFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#ilikiaFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#arxikoOnomatosFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#arxikoEpithetouFieldColumn")
                .classList.add("disabled");
        }

        if (
            (epithetoValue == "" ||
                onomaValue == "" ||
                onomaPatrosValue == "") &&
            (apotelesmaBDokimasiwnValue == 1 ||
                eidosDotiValue == 2 ||
                latestApotelesmaBDokimasiwn)
        ) {
            window.Toast.fire({
                icon: "success",
                title: 'Μπορείτε να συμπληρώσετε τα στοιχεία του δότη στην καρτέλα "ΑΡΧΙΚΗ - ΕΝΤΟΠΙΣΜΟΣ"',
            });
        }
    }

    // =======================================
    // Apotelesma Dokimasiwn A or B Field Logic
    // =======================================
    apotelesmaDokimasiwnChange() {
        // Init: Hide fields
        document
            .querySelector(
                "#apotelesmataDokimasiasArnitikaGiaEgkThanatoFieldColumn"
            )
            .classList.add("disabled");

        let apotelesmaADokimasiwnValue = document.querySelector(
            "#apotelesma-a-dokimasiwn"
        ).value;
        let apotelesmaBDokimasiwnValue = document.querySelector(
            "#apotelesma-b-dokimasiwn"
        ).value;
        if (
            apotelesmaADokimasiwnValue == 2 ||
            apotelesmaBDokimasiwnValue == 2
        ) {
            document
                .querySelector(
                    "#apotelesmataDokimasiasArnitikaGiaEgkThanatoFieldColumn"
                )
                .classList.remove("disabled");
        } else {
            document
                .querySelector(
                    "#apotelesmataDokimasiasArnitikaGiaEgkThanatoFieldColumn"
                )
                .classList.add("disabled");
        }
    }

    // =======================================
    // Apotelesma A Dokimasiwn Field Logic
    // =======================================
    apotelesmaΑDokimasiwnChange() {
        // Init: Hide fields
        document
            .querySelector("#apotelesmaBDokimasiwnFieldColumn")
            .classList.add("disabled");

        let apotelesmaADokimasiwnValue = document.querySelector(
            "#apotelesma-a-dokimasiwn"
        ).value;

        if (apotelesmaADokimasiwnValue == 2) {
            document
                .querySelector("#apotelesmaBDokimasiwnFieldColumn")
                .classList.add("disabled");
        } else {
            document
                .querySelector("#apotelesmaBDokimasiwnFieldColumn")
                .classList.remove("disabled");
        }
    }

    // =======================================
    // Telesi Aggeiografias Field Logic
    // =======================================
    telesiAggeiografeiasChange() {
        // Init: Hide fields
        document
            .querySelector("#apotelesmaAggeiografiasFieldColumn")
            .classList.add("disabled");

        let telesiAggeiografiasValue = document.querySelector(
            "#telesi-aggeiografias"
        ).value;
        if (telesiAggeiografiasValue == 2) {
            document
                .querySelector("#apotelesmaAggeiografiasFieldColumn")
                .classList.add("disabled");
        } else {
            document
                .querySelector("#apotelesmaAggeiografiasFieldColumn")
                .classList.remove("disabled");
        }
    }

    // =======================================
    // Sizitithike Opiadipote Stigmi Field Logic
    // =======================================
    sizitithikeOpiadipoteStigmiChange() {
        document
            .querySelector("#simperasmaSizitisisFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector(
                "#poteSizitithikeTinIkogeniaEndechomenoTisDoreasOrganonFieldColumn"
            )
            .classList.remove("disabled");
        document
            .querySelector("#simperasmaSizitisisFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#arnisiDoreasAitiesFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector(
                "#piosEnimeroseTinIkogeniaGiaEndechomenoTisDoreasFieldColumn"
            )
            .classList.remove("disabled");
        document
            .querySelector("#pouPragmatopiithikeEnimerosiFieldColumn")
            .classList.remove("disabled");

        let sizitithikeOpiadipoteStigmiValue = document.querySelector(
            "#sizitithike-opiadipote-stigmi"
        ).value;
        if (sizitithikeOpiadipoteStigmiValue == 1) {
            document
                .querySelector("#simperasmaSizitisisFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector(
                    "#poteSizitithikeTinIkogeniaEndechomenoTisDoreasOrganonFieldColumn"
                )
                .classList.remove("disabled");
            document
                .querySelector("#simperasmaSizitisisFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#arnisiDoreasAitiesFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector(
                    "#piosEnimeroseTinIkogeniaGiaEndechomenoTisDoreasFieldColumn"
                )
                .classList.remove("disabled");
            document
                .querySelector("#pouPragmatopiithikeEnimerosiFieldColumn")
                .classList.remove("disabled");
        } else {
            document
                .querySelector("#simperasmaSizitisisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector(
                    "#poteSizitithikeTinIkogeniaEndechomenoTisDoreasOrganonFieldColumn"
                )
                .classList.add("disabled");
            document
                .querySelector("#simperasmaSizitisisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#arnisiDoreasAitiesFieldColumn")
                .classList.add("disabled");
            document
                .querySelector(
                    "#piosEnimeroseTinIkogeniaGiaEndechomenoTisDoreasFieldColumn"
                )
                .classList.add("disabled");
            document
                .querySelector("#pouPragmatopiithikeEnimerosiFieldColumn")
                .classList.add("disabled");
        }
    }

    // =======================================
    // Simperasma Sizitisis Stigmi Field Logic
    // =======================================
    simperasmaSizitisisChange() {
        document
            .querySelector("#arnisiDoreasAitiesFieldColumn")
            .classList.remove("disabled");

        let simperasmaSizitisisValue = document.querySelector(
            "#simperasma-sizitisis"
        ).value;
        if (simperasmaSizitisisValue == 1) {
            document
                .querySelector("#arnisiDoreasAitiesFieldColumn")
                .classList.add("disabled");
        } else {
            document
                .querySelector("#arnisiDoreasAitiesFieldColumn")
                .classList.remove("disabled");
        }
    }

    // =======================================
    // Pragmatopiithike Lipsi Field Logic
    // =======================================
    pragmatopiithikeLipsiChange() {
        document
            .querySelector("#aitiesMiLipsisFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#organaPouElifthisanFieldColumn")
            .classList.remove("disabled");

        let pragmatopiithikeLipsiValue = document.querySelector(
            "#pragmatopiithike-lipsi"
        ).value;
        if (pragmatopiithikeLipsiValue == 4) {
            document
                .querySelector("#aitiesMiLipsisFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#organaPouElifthisanFieldColumn")
                .classList.add("disabled");
        } else if (
            pragmatopiithikeLipsiValue == 1 ||
            pragmatopiithikeLipsiValue == 2
        ) {
            document
                .querySelector("#organaPouElifthisanFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#aitiesMiLipsisFieldColumn")
                .classList.add("disabled");
        } else {
            document
                .querySelector("#aitiesMiLipsisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#organaPouElifthisanFieldColumn")
                .classList.add("disabled");
        }
    }

    // =======================================
    // Aities Mi LipsisC Field Logic
    // =======================================
    aitiesMiLipsisChange() {
        document
            .querySelector("#imerominiaChirourgiouFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#oraEnarxisChOuFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#oraLixisChOuFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#egchoriesOmadesAfairesisFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#xenesOmadesAfairesisFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#organaPouElifthisanFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#istiPouElifthisanFieldColumn")
            .classList.remove("disabled");
        document
            .querySelector("#anepithimitaSimvantaFieldColumn")
            .classList.remove("disabled");

        let aitiesMiLipsisValue =
            document.querySelector("#aities-mi-lipsis").value;
        if (aitiesMiLipsisValue == 1) {
            document
                .querySelector("#imerominiaChirourgiouFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#oraEnarxisChOuFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#oraLixisChOuFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#egchoriesOmadesAfairesisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#xenesOmadesAfairesisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#organaPouElifthisanFieldColumn")
                .classList.add("disabled");
        } else if (aitiesMiLipsisValue == 2) {
            document
                .querySelector("#imerominiaChirourgiouFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#oraEnarxisChOuFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#oraLixisChOuFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#egchoriesOmadesAfairesisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#xenesOmadesAfairesisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#organaPouElifthisanFieldColumn")
                .classList.add("disabled");
        } else if (aitiesMiLipsisValue == 3) {
            document
                .querySelector("#imerominiaChirourgiouFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#oraEnarxisChOuFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#oraLixisChOuFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#egchoriesOmadesAfairesisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#xenesOmadesAfairesisFieldColumn")
                .classList.add("disabled");
            document
                .querySelector("#organaPouElifthisanFieldColumn")
                .classList.add("disabled");
        } else {
            document
                .querySelector("#imerominiaChirourgiouFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#oraEnarxisChOuFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#oraLixisChOuFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#egchoriesOmadesAfairesisFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#xenesOmadesAfairesisFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#organaPouElifthisanFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#istiPouElifthisanFieldColumn")
                .classList.remove("disabled");
            document
                .querySelector("#anepithimitaSimvantaFieldColumn")
                .classList.remove("disabled");
        }
    }
}
