// https: //mymth.github.io/vanillajs-datepicker/
import { Datepicker, DateRangePicker } from "vanillajs-datepicker";

import el from "../../node_modules/vanillajs-datepicker/js/i18n/locales/el.js";
Object.assign(Datepicker.locales, el);

export default class {
    constructor() {
        let that = this;
        if (document.querySelector("#incidentsFilter")) {
            $("#incidentsFilter").on("change", function () {
                that.updateUrl("incidentsFilter", this.value);
            });
        }

        this.setDatepickers();
    }
    setDatepickers() {
        let that = this;
        const elem = document.getElementById("range");
        const filtersDateFrom = document.getElementById("filters-date-from");
        const filtersDateTo = document.getElementById("filters-date-to");

        const dateRangePicker = new DateRangePicker(elem, {
            autohide: true,
            format: "dd/mm/yyyy",
            language: "el",
        });

        filtersDateFrom.addEventListener('changeDate', (event) => {
            console.log(filtersDateFrom.value);
            that.updateUrl("filtersDateFrom", filtersDateFrom.value);
        });
        filtersDateTo.addEventListener('changeDate', (event) => {
            console.log(filtersDateTo.value);
            that.updateUrl("filtersDateTo", filtersDateTo.value);
        });
    }

    updateUrl(paramName, paramValue) {
        let url = new URL(window.location.href);
        url.searchParams.set(paramName, paramValue);
        window.location.href = url;
    }
}
